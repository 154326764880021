const baseUrl = process.env.REACT_APP_API_DOMAIN + "/api";

/**
 *
 * @param {string} url
 * @param {any} options
 */
async function GET(url, options) {
  let _headers = {
    "Content-Type": "Application/Json",
  };

  if (options && options.headers) {
    _headers = { ..._headers, ...options.headers };
  }

  const res = await (
    await fetch(baseUrl + url, {
      method: "GET",
      headers: _headers,
    })
  ).json();

  return res;
}

/**
 *
 * @param {string} url
 * @param {any} data
 * @param {any} options
 */
async function POST(url, data, options) {
  console.log("Dta", data);
  let _headers = {
    "Content-Type": "Application/Json",
  };

  if (options && options.headers) {
    _headers = { ..._headers, ...options.headers };
  }

  const res = await (
    await fetch(baseUrl + url, {
      method: "POST",
      headers: _headers,
      body: JSON.stringify(data),
    })
  ).json();

  return res;
}

/**
 *
 * @param {string} url
 * @param {any} data
 * @param {any} options
 */
async function DELETE(url, data, options) {
  console.log("Dta", data);
  let _headers = {
    "Content-Type": "Application/Json",
  };

  if (options && options.headers) {
    _headers = { ..._headers, ...options.headers };
  }

  const res = await (
    await fetch(baseUrl + url, {
      method: "DELETE",
      headers: _headers,
      body: JSON.stringify(data),
    })
  ).json();
  console.log(res);
  return res;
}

const Network = { GET, POST, DELETE };

export default Network;
