// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// import Pipeline from "../src/components/pipeline/pipeline";
// import Source from "../src/components/source/source";
// import ProcessComponent from "./components/process/processing-component";
// import DestinationComponent from "./components/destination/destination-component";
// import User from "../src/components/user/user";
// import "./App.scss";
// import AzureLogin from "./components/auth/azureLoginPage";
// import CreateUser from "./components/user/createUser";
// import AuthPage from "./components/auth/index.js.js";
// import RedirectIfAuthenticatedRoute from "./components/routes/RedirectIfAuthenticatedRoute";
// import AuthenticateRoute from "./components/routes/AuthenticateRoute";

// function App() {
//   return (
//     <div className="App">
//       <Router>
//         {/* <NavBar /> */}
//         <Routes>
//           <Route
//             path="/home"
//             element={
//               <AuthenticateRoute>
//                 <Pipeline />
//               </AuthenticateRoute>
//             }
//           />
//           <Route path="/source" element={<Source />} />
//           <Route path="/processing" element={<ProcessComponent />} />
//           <Route path="/destination" element={<DestinationComponent />} />
//           <Route path="/user" element={<User />} />
//           <Route
//             path="/auth"
//             element={
//               <RedirectIfAuthenticatedRoute>
//                 <AzureLogin />
//               </RedirectIfAuthenticatedRoute>
//             }
//           />
//           <Route path="/createUser" element={<CreateUser />} />
//           <Route path="/" element={<AuthPage />} />
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;
import React from "react";
import ReduxToastr from "react-redux-toastr";
import NavRoutes from "./components/routes/Routes";
// import { Provider } from "react-redux";
// import store from "./store/store";
// import { persistor, store } from "./store/reducers";
import { BrowserRouter } from "react-router-dom";
// import { PersistGate } from "redux-persist/integration/react";

const App = () => {
  return (
    <>
      {/* <Provider store={store}> */}
      <BrowserRouter>
        {/* <PersistGate loading={null} persistor={persistor}></PersistGate> */}
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={true}
        />

        <NavRoutes />
      </BrowserRouter>
      {/* </Provider> */}
    </>
  );
};

export default App;
