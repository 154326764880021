import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AzureLogin from "../auth/Azurelogin";
import AuthenticateRoute from "./AuthenticateRoute";
import RedirectIfAuthenticatedRoute from "./RedirectIfAuthenticatedRoute";
import { RouteKeys } from "./route-keys";
import Pipeline from "../pipeline/pipeline";
const NotFound = lazy(() => import("../notfound/NotFound"));
const AuthPage = lazy(() => import("../auth"));
const CreateUser = lazy(() => import("../../components/user/user"));
const Source = lazy(() => import("../../components/source/source"));
const Processing = lazy(() =>
  import("../../components/process/processing-component")
);
const Destination = lazy(() =>
  import("../../components/destination/destination-component")
);
const User = lazy(() => import("../../components/user/user"));

class NavRoutes extends React.Component {
  render() {
    return (
      <Suspense fallback={<></>}>
        <Routes>
          <>
            <Route
              path={RouteKeys.Home}
              element={
                <AuthenticateRoute>
                  <Pipeline />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Source}
              element={
                <AuthenticateRoute>
                  <Source />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Processing}
              element={
                <AuthenticateRoute>
                  <Processing />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Destination}
              element={
                <AuthenticateRoute>
                  <Destination />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.User}
              element={
                <AuthenticateRoute>
                  <User />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Auth}
              element={
                <RedirectIfAuthenticatedRoute>
                  <AuthPage />
                </RedirectIfAuthenticatedRoute>
              }
            />
            <Route
              path={RouteKeys.AzureLogin}
              element={
                <RedirectIfAuthenticatedRoute>
                  <AzureLogin />
                </RedirectIfAuthenticatedRoute>
              }
            />
            <Route
              path={RouteKeys.CreateUser}
              element={
                <AuthenticateRoute>
                  <CreateUser />
                </AuthenticateRoute>
              }
            />
            <Route
              path={RouteKeys.Pipeline}
              element={
                <AuthenticateRoute>
                  <Pipeline />
                </AuthenticateRoute>
              }
            />
            <Route path="*" element={<NotFound></NotFound>} />
            <Route path={RouteKeys.NotFound} element={<></>} />
          </>
        </Routes>
      </Suspense>
    );
  }
}

export default NavRoutes;
