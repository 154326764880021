import { stringify } from "query-string";
import * as http from "./http";
import ApiService from "./service";

export class QuickSightService extends ApiService {
  async getEmbeddedURL(request) {
    const url = `${this.apiDomain}/${request.resource}?${stringify(
      request.checktype
    )}`;
    const response = await http
      .get(url, this.store)
      .catch((err) => console.log(err));
    return { data: response.data };
  }
}
