import { pipeline } from "../../utilities/constants/constants";
import { createAction, createActionWithPayload } from "../utils";

//action types
//updatesource
const UPDATE_SOURCE = "AUTH/UPDATE_SOURCE";
//updateprocessing
const UPDATE_PROCESSING = "AUTH/UPDATE_PROCESSING";
//updatedestination
const UPDATE_DESTNATION = "AUTH/UPDATE_DESTINATION";
//setdataconnectivity
const SET_DATA_CONNECTIVITY = "AUTH/DATA_CONNECTIVITY";
//updatePipeline
const UPDATE_PIPELINE = "AUTH/UPDATE_PIPELINE";
//updateToken
const UPDATE_TOKEN = "AUTH/UPDATE_TOKEN";
//updateDataStorage
const UPDATE_DATA_STORAGE = "AUTH/UPDATE_DATA_STORAGE";
//updateName
const UPDATE_NAME = "AUTH/UPDATE_NAME";
//updateUserName
const UPDATE_USER_NAME = "AUTH/UPDATE_USER_NAME";

//initial state
const initialState = {
  pipelineData: pipeline,
  data_storage: [],
  dataConnectivity: {},
  username: "",
  jwtIdToken: "",
};

export const updateSource = (data) => {
  // console.log("updateSourceData_slicer", data);
  return createActionWithPayload(UPDATE_SOURCE, data);
};
export const updateProcessing = (data) => {
  console.log("updateProcessing_slicer", data);
  return createActionWithPayload(UPDATE_PROCESSING, data);
};
export const updateDestination = () => {
  return createAction(UPDATE_DESTNATION);
};
export const setDataConnectivity = (data) => {
  // console.log("setDataConnectivity_Slicer", data);
  return createActionWithPayload(SET_DATA_CONNECTIVITY, data);
};
export const updatePipeline = (data) => {
  console.log("updatePipeline_slicer", data);
  return createActionWithPayload(UPDATE_PIPELINE, data);
};
export const updateToken = () => {
  return createAction(UPDATE_TOKEN);
};
export const updateDataStorage = (data) => {
  return createActionWithPayload(UPDATE_DATA_STORAGE, data);
};
export const updateName = () => {
  return createAction(UPDATE_NAME);
};
export const updateUserName = () => {
  return createAction(UPDATE_USER_NAME);
};

//reducer
export const appSlicerReducer = (state = initialState, action) => {
  // console.log({ action });
  switch (action.type) {
    //updateSource
    case UPDATE_SOURCE:
      // console.log(action.payload);
      // console.log("Update source state", state);
      // console.log("Update source Initialstate", initialState);
      state.pipelineData.source = action.payload;
      console.log("Inside UpdateSource", state);
      return state;

    case UPDATE_DESTNATION:
      return {
        [state.pipelineData.destination]: [action.payload.destination],
        [state.pipelineData.schedule]: [action.payload.schedule],
        [state.pipelineData.notifications]: [action.payload.notifications],
        [state.pipelineData.triggers]: [action.payload.triggers],
      };

    case UPDATE_PIPELINE:
      console.log("action.payload_update_pipelineValue", action.payload);
      state.pipelineValue = action.payload;
      console.log("state_update_pipelineValue", state.pipelineValue);
      return state;

    case UPDATE_PROCESSING:
      console.log("action.payload_update_processing", action.payload);
      state.pipelineData.processing = action.payload;
      console.log("state_update_processing", action.payload);
      return state;

    case SET_DATA_CONNECTIVITY:
      // console.log({ setData: action.payload });
      state["dataConnectivity"] = action.payload;
      // console.log("Inside data connectivity", state);
      return state;

    case UPDATE_TOKEN:
      state.jwtIdToken = action.payload;
      return state;

    case UPDATE_DATA_STORAGE:
      // console.log("Inside dataStorage", state);
      state.data_storage = action.payload;
      // console.log("Inside data storage", state);
      return state;

    case UPDATE_NAME:
      state.pipelineData.name = action.payload;
      return state;

    case UPDATE_USER_NAME:
      state.username = action.payload;
      return state;
    default:
      return state;
  }
};
