export const RouteKeys = {
  // Login: "/auth/login",
  Home: "/",
  Auth: "/auth",
  AzureLogin: "/auth/aad-redirect",
  Pipeline: "/pipeline",
  Source: "/source",
  Processing: "/processing",
  Destination: "/destination",
  User: "/user",
  NotFound: "/page-not-found",
};
