import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import "../pipeline/pipeline.scss";
import Button from "react-bootstrap/Button";
import { IoAddCircle } from "react-icons/io5";
import Table from "react-bootstrap/Table";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Nav from "react-bootstrap/Nav";
import NavBar from "../navbar/navbar";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import Network from "../../utilities/Network";
import { NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { updatePipeline } from "../../store/slicers/AppSlicer";

function Pipeline() {
  const [pipelineList, setPipelineLists] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    LoadPipelines();
  }, []);

  async function LoadPipelines() {
    try {
      const res = await Network.GET("/pipeline/list");
      console.log(res);

      setPipelineLists(res.data);
    } catch (ex) {
      console.log(ex);
    }
  }
  async function Edit(i) {
    const pipelineValue = pipelineList[i];
    console.log("======", pipelineValue);
    dispatch(updatePipeline(pipelineValue));
    navigate(`/source`);
  }

  function GetDateString(data) {
    let dt = new Date(data);

    if (dt) {
      dt = dt.toString().split("GMT")[0].trim();
      return dt;
    } else {
      return "N/A";
    }
  }

  const deleteValue = async (targetIndex) => {
    console.log("targetIndex", targetIndex);
    let value = {};
    for (var i = 0; i < pipelineList.length; i++) {
      if (targetIndex === i) {
        value = pipelineList[i];
      }
    }
    handleDelete(value);
  };
  const handleDelete = async (data) => {
    try {
      if (window.confirm("Are you sure to delete?")) {
        const res = await Network.DELETE("/pipeline/delete", data);
        window.location.reload();

        if (res.status !== 200) {
          return toastr.error("Error", res?.message);
        }
      }
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  return (
    <>
      <div>
        <NavBar />
        <Card className="card-1">
          <Nav.Link href="/source">
            <Card.Body>
              Analyse and observe the data
              <Button className="add-btn">
                <IoAddCircle
                  size={"1.2rem"}
                  style={{
                    marginRight: "0.2rem",
                    marginLeft: "0.3rem",
                    marginTop: "3px",
                    marginBottom: "3px",
                  }}
                />
                ADD
              </Button>
            </Card.Body>
          </Nav.Link>
        </Card>
        <NavItem>
          <Link to="/user">
            <Button className="add-btn">
              <IoAddCircle
                size={"1.2rem"}
                style={{
                  marginRight: "0.2rem",
                  marginLeft: "0.3rem",
                  marginTop: "3px",
                  marginBottom: "3px",
                }}
              />
              Users
            </Button>
          </Link>
        </NavItem>

        <Table className="pipeline-table" class>
          <thead
            style={{
              backgroundColor: "#5A5A5A",
              color: "#ffffff",
              height: "2.8rem",
            }}
          >
            <tr>
              <th>Pipeline name</th>
              <th>Created On</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody style={{ padding: "5rem", color: "#606060" }}>
            {pipelineList.map((pipe, i) => {
              return (
                <tr key={i}>
                  <td>{pipe.data.name}</td>
                  <td>{GetDateString(pipe.createddate)}</td>
                  <td>{pipe.status}</td>
                  <td>
                    <AiTwotoneEdit onClick={() => Edit(i)} />
                    <MdDelete
                      className="delete-btn"
                      onClick={() => deleteValue(i)}
                    />
                  </td>
                </tr>
              );
            })}

            {/* <tr >
							<td style={{ height: '3.2rem' }}><ImCheckboxUnchecked /></td>
							<td >Umg-source-empire-deezer-streams</td>
							<td>Feb 07, 2023. 10:21:19</td>
							<td>Inprogress</td>
							<td><AiTwotoneEdit /> <MdDelete /></td>
						</tr>
						<tr>
							<td style={{ height: '3.2rem' }}><ImCheckboxUnchecked /></td>
							<td>Umg-source-empire-deezer-streams</td>
							<td>Feb 07, 2023. 10:21:19</td>
							<td>Inprogress</td>
							<td><AiTwotoneEdit /> <MdDelete /></td>

						</tr>
						<tr>
							<td style={{ height: '3.2rem' }}><ImCheckboxUnchecked /></td>
							<td>Umg-source-empire-deezer-streams</td>
							<td>Feb 07, 2023. 10:21:19</td>
							<td>Inprogress</td>
							<td><AiTwotoneEdit /> <MdDelete /></td>

						</tr>

						<tr>
							<td style={{ height: '3.2rem' }}><ImCheckboxUnchecked /></td>
							<td>Umg-source-empire-deezer-streams</td>
							<td>Feb 07, 2023. 10:21:19</td>
							<td>Inprogress</td>
							<td><AiTwotoneEdit /> <MdDelete /></td>

						</tr>

						<tr>
							<td style={{ height: '3.2rem' }}><ImCheckboxUnchecked /></td>
							<td>Umg-source-empire-deezer-streams</td>
							<td>Feb 07, 2023. 10:21:19</td>
							<td>Inprogress</td>
							<td><AiTwotoneEdit /> <MdDelete /></td>

						</tr>
						<tr>
							<td style={{ height: '3.2rem' }}><ImCheckboxUnchecked /></td>
							<td>Umg-source-empire-deezer-streams</td>
							<td>Feb 07, 2023. 10:21:19</td>
							<td>Inprogress</td>
							<td><AiTwotoneEdit /> <MdDelete /></td>

						</tr>

						<tr>
							<td style={{ height: '3.2rem' }}><ImCheckboxUnchecked /></td>
							<td>Umg-source-empire-deezer-streams</td>
							<td>Feb 07, 2023. 10:21:19</td>
							<td>Inprogress</td>
							<td><AiTwotoneEdit /> <MdDelete /></td>

						</tr> */}
          </tbody>
        </Table>

        {/* <div className="pagination d-flex">
                         <div className="records">
                              Records per page
                         </div>
                         <div className="box-icon d-flex">
                              <ImCheckboxUnchecked size={'1rem'} />
                              <div className="check"></div>
                              <div className="double-left">
                                   <FaAngleDoubleLeft />
                              </div>
                              <div className="arrow-back">
                                   <IoIosArrowBack />
                              </div>
                              <div className="paging">
                                   Page 1 of 1
                              </div>
                              <div className="arrow-right">
                                   <IoIosArrowForward />
                              </div>
                              <div className="double-right">
                                   <FaAngleDoubleRight />
                              </div>

                         </div>
                    </div> */}
      </div>
    </>
  );
}

export default Pipeline;
