import { DISTRIBUTION, FRESHNESS, SCHEMA, VOLUME } from './variables';

export const formatConfigurationData = (data, checkType) => {
    // let res = [];
    let temp = {
        table_name: data['table_name'],
        column_name: null,
        date_field: null,
        frequency: null,
        run_day: null,
        run_time: null,
        data_delay: null,
        group_by_col: null,
        agg_type: null,
        additional_threshold: null,
    };
    // checkTypes.current.forEach((item) => {
    if (checkType === FRESHNESS) {
        temp = {
            ...temp,
            check_type: 'freshness',
            date_field: data['date_field'],
            frequency: data['frequency'],
            data_delay: data['data_delay'],
            run_day: Number(data['run_day']),
            run_time: data['run_time'],
        };
    }
    if (checkType === SCHEMA) {
        temp = {
            ...temp,
            check_type: 'schema',
        };
    }
    if (checkType === VOLUME) {
        temp = {
            ...temp,
            check_type: 'volume',
            column_name: data['column_name'],
            date_field: data['date_field'],
            group_by_col: data['group_by_col'] || null,
            additional_threshold: data['additional_threshold'] || null,
        };
    }
    if (checkType === DISTRIBUTION) {
        temp = {
            ...temp,
            check_type: 'distribution',
            column_name: data['column_name'],
            date_field: data['date_field'],
            additional_threshold: data['additional_threshold'] || null,
        };
    }
    // });
    return temp;
};
